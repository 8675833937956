/// <reference path="o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts" />

import type { AppState } from '365.pwa.types.ts';
import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as PWAStateModule from 'o365.pwa.declaration.shared.dexie.objectStores.PWAState.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const indexedDBHandlerImportmapEntry = self.o365.getImportMapEntryFromImportUrl('o365.pwa.modules.sw.IndexedDBHandler.ts');

    class PWAState implements PWAStateModule.PWAState {
        static objectStoreDexieSchema: string = "&appId";

        public appId!: string;
        public appState!: AppState;
        public hasDatabaseConnection!: boolean;
        public isAppInstalled!: boolean;
        public hasHtmlUpdate!: boolean;
        public hasAppResourceUpdate!: boolean;
        public hasServiceWorkerUpdate!: boolean;
        public debugUi!: boolean;
        public debugMergeProc!: boolean;
        public hasLocalData!: boolean;
        public lastSync!: {
            [key: string]: Date
        };

        public get hasAppUpdateAvailable() {
            return this.hasHtmlUpdate || this.hasAppResourceUpdate || this.hasServiceWorkerUpdate;
        }

        constructor(appId: string, appState: AppState = 'OFFLINE', hasDatabaseConnection: boolean) {
            this.appId = appId;
            this.appState = appState;
            this.hasDatabaseConnection = hasDatabaseConnection;
            this.isAppInstalled = false;
            this.hasHtmlUpdate = false;
            this.hasAppResourceUpdate = false;
            this.debugUi = false;
            this.debugMergeProc = false;
            this.hasLocalData = false;
            this.lastSync = {};
            this.hasServiceWorkerUpdate = false;
        }

        public async save(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.updatePWAState(this);
        }

        public async delete(): Promise<void> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            await IndexedDBHandler.deletePWAState(this);
        }

        public async forceReload(): Promise<PWAState | null> {
            const { IndexedDBHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.shared.IndexedDBHandler.d.ts')>("o365.pwa.modules.sw.IndexedDBHandler.ts", indexedDBHandlerImportmapEntry);

            return await IndexedDBHandler.getPWAStateFromIndexedDB(this.appId);
        }
    }

    self.o365.exportScripts({ PWAState });

})();